import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure, Tooltip, Box } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import LinkButton from '../common/LinkButton';
import { createColumnHelper } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import { BsTrash, BsBuildingAdd } from 'react-icons/bs';
import { useAddFacilityMutation, useRemoveFacilityMutation } from '@/app/services/nucleus';
import {
  selectFacilitySearchStatus,
  selectContractFacilities,
  setFacilitySearchStatus,
  selectGetContractErrorMsg,
  selectGetContractStatus,
} from '../contract/contractSlice';
import TableButton from '../common/table/TableButton';
import RemoveFacility from './RemoveFacility';
import AddFacility from './AddFacility';
import {
  selectFacilitySortBy,
  selectFacilitySortDirection,
  selectFacilityStatus,
  setFacilitySortBy,
  setFacilitySortDirection,
} from './facilitySlice';
import { ERROR, LOADING } from '@/utils/constants';
import TruncatedTextComponent from '../common/TruncatedTextComponent';
import { isArrayEmpty, isArrayValue, isRealValue } from '@/utils/helpers';
import { facilityFilterOptions, removeFacilityAction, searchColumns } from '@/features/facility/facilityHelper';
import DataTableSearchAndFiltering from '@/features/common/table/DataTableSearchAndFiltering';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { useNotification } from '@/hooks/useNotification';

const FacilityTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const addFacilityModal = useDisclosure();
  const removeFacilityModal = useDisclosure();
  const contractFacilities = useSelector(selectContractFacilities);
  const facilityStatus = useSelector(selectFacilitySearchStatus);
  const sortBy = useSelector(selectFacilitySortBy);
  const sortDirection = useSelector(selectFacilitySortDirection);
  const [selectedFacility, setSelectedFacility] = useState({});
  const columnHelper = createColumnHelper();
  const getFacilitiesStatus = useSelector(selectFacilityStatus);
  const getContractStatus = useSelector(selectGetContractStatus);
  const contractError = useSelector(selectGetContractErrorMsg);

  const [filterValue, setFilterValue] = useSessionStorage('facility_do_not_sell', null);

  const [addFacility, { isLoading: addFacilityLoading, isSuccess: addFacilitySuccess, isError: addFacilityError, error: addFacilityErrorMsg }] =
    useAddFacilityMutation();
  const [
    removeFacility,
    { isLoading: removeFacilityLoading, isSuccess: facilityRemovedSuccessfully, isError: removeFacilityError, error: removeFacilityErrorMsg },
  ] = useRemoveFacilityMutation();

  useEffect(() => {
    if (isRealValue(filterValue)) {
      setFilterValue(filterValue);
    } else {
      setFilterValue(null);
    }
  }, []);

  const filterContractFacilities = (status, allData, filterData) => {
    if ((status && status.length === 0) || !isRealValue(allData) || allData?.length === 0) {
      return allData || [];
    }
    const filteredFinalResults = isArrayValue(status)
      ? filterData.filter((result) => {
          let task = false;
          const statuses = status.reduce((prev, cur) => [...prev, cur.value], []);
          // archived is not a real status so we check is_deleted
          for (const status of statuses) {
            if (task === true) break;
            if (status === 'is_do_not_sell') {
              task = result?.is_do_not_sell === true;
            } else if (status === 'active') {
              task = result?.is_do_not_sell === false;
            }
          }
          return task;
        })
      : [];
    // if there are no results and no status, return all contracts
    if (filteredFinalResults.length === 0 && isArrayEmpty(status)) {
      return allData;
    } else {
      return filteredFinalResults;
    }
  };

  const handleFacilitiesSortBy = (sortBy) => {
    dispatch(setFacilitySortBy(sortBy));
  };

  const handleFacilitiesSortDirection = (sortDir) => {
    dispatch(setFacilitySortDirection(sortDir));
  };

  const handleRemoveFacilityActions = (e, info) => {
    e.preventDefault();
    setSelectedFacility(info.row.original);
    removeFacilityModal.onOpen();
  };

  const facilityColumns = [
    columnHelper.accessor('name', {
      cell: (info) => <TruncatedTextComponent length={30} title="Facility Name" text={info.getValue()} />,
      header: 'Name',
      size: 375,
    }),
    columnHelper.accessor('address', {
      cell: (info) => info.getValue(),
      header: 'Address',
      size: 170,
    }),
    columnHelper.accessor('city', {
      cell: (info) => info.getValue(),
      header: 'City',
      size: 170,
    }),
    columnHelper.accessor('state', {
      cell: (info) => info.getValue(),
      header: 'State',
    }),
    columnHelper.accessor('is_do_not_sell', {
      cell: (info) => (info.getValue() ? 'Do Not Sell' : 'Active'),
      header: 'status',
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        return (
          <Tooltip hasArrow placement="top" label="Remove Facility">
            <Box p="0" as="span">
              <TableButton aria-label="remove" icon={<BsTrash />} onClick={(e) => handleRemoveFacilityActions(e, info)} />
            </Box>
          </Tooltip>
        );
      },
      header: 'Action',
    }),
  ];

  const handleFacilitySearchStatus = (text) => {
    dispatch(setFacilitySearchStatus(text));
  };

  const handleAddFacility = (e) => {
    addFacilityModal.onOpen();
  };

  const handleSaveFacility = (e, facilityId) => {
    let asyncAddFacility = async () => {
      try {
        const result = await addFacility({ contractId: id, facilityId: facilityId });
        unwrapResult(result);
      } catch (error) {
        console.log(error);
      }
    };
    asyncAddFacility().catch((error) => {
      console.log(error);
    });
  };

  useNotification(facilityRemovedSuccessfully, removeFacilityModal.onClose, facilityRemovedSuccessfully, 'success', 'Facility removed');

  const handleRemoveFacility = (facilityId) => {
    removeFacilityAction(removeFacility, id, facilityId);
  };

  return (
    <React.Fragment>
      <DataTableSearchAndFiltering
        title="Facilities"
        originalData={contractFacilities}
        filterValue={filterValue}
        handleFilterValue={setFilterValue}
        filterFunction={filterContractFacilities}
        filterOptions={facilityFilterOptions}
        filterPlaceholder={'Filter by Status'}
        searchStatus={facilityStatus}
        isSearchLoading={facilityStatus === LOADING}
        isSearchError={facilityStatus === ERROR || getFacilitiesStatus === ERROR}
        isDataLoading={getContractStatus === LOADING}
        isDataError={getContractStatus === ERROR}
        dataErrorMsg={contractError?.data?.detail}
        dataErrorTitle={`Error fetching contract ${id}`}
        dataColumns={facilityColumns}
        searchColumns={searchColumns}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleFacilitiesSortBy}
        handleSortDirection={handleFacilitiesSortDirection}
        handleSearchStatus={handleFacilitySearchStatus}
      >
        <LinkButton icon={<BsBuildingAdd />} onClick={(e) => handleAddFacility(e)}>
          Add Facility
        </LinkButton>
      </DataTableSearchAndFiltering>
      <AddFacility
        id={id}
        onOpen={addFacilityModal.onOpen}
        onClose={addFacilityModal.onClose}
        isOpen={addFacilityModal.isOpen}
        isLoading={addFacilityLoading}
        isSuccess={addFacilitySuccess}
        isError={addFacilityError}
        errorMsg={addFacilityErrorMsg}
        onSaveHandler={handleSaveFacility}
      />
      <RemoveFacility
        id={id}
        facility={selectedFacility}
        onOpen={removeFacilityModal.onOpen}
        onClose={removeFacilityModal.onClose}
        isOpen={removeFacilityModal.isOpen}
        isLoading={removeFacilityLoading}
        isError={removeFacilityError}
        errorMsg={removeFacilityErrorMsg}
        removeFacilityHandler={handleRemoveFacility}
      />
    </React.Fragment>
  );
};

export default FacilityTab;
