import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';

const initialState = {
  searchStatus: '',
  sortBy: 'division',
  sortDirection: 'asc',
};

export const jobClosingSlice = createSlice({
  name: 'jobClosingRules',
  initialState,
  reducers: {
    setSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.getJobClosingRules.matchFulfilled, (state, { payload }) => {
      if (payload && payload?.items) {
        state.jobClosingRules = payload.items;
      }
    });
  },
});

export const { setJobClosingRules, setSearchStatus, setSortBy, setSortDirection } = jobClosingSlice.actions;

export const selectJobClosingSearchStatus = (status) => status.jobs.searchStatus;
export const selectJobClosingSortBy = (status) => status.jobs.sortBy;
export const selectJobClosingSortDirection = (status) => status.jobs.sortDirection;

export default jobClosingSlice.reducer;
