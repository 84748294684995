import { Box, Button, Heading, HStack, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { BsPencilSquare } from 'react-icons/bs';
import { useGetJobClosingRulesQuery } from '@/app/services/nucleus';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectJobClosingSearchStatus,
  selectJobClosingSortBy,
  selectJobClosingSortDirection,
  setSearchStatus,
  setSortBy,
  setSortDirection,
} from './jobClosingSlice';
import { ERROR, LOADING } from '@/utils/constants';
import { createColumnHelper } from '@tanstack/react-table';
import TableButton from '../common/table/TableButton';
import JobClosingModal from './JobClosingModal';
import { setNotificationLogMessage } from '../notification/notificationSlice';
import { useRole } from '@/hooks/useRole';

const JobClosingRules = () => {
  useRole('support');
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const jobsModal = useDisclosure();

  const searchStatus = useSelector(selectJobClosingSearchStatus);
  const sortBy = useSelector(selectJobClosingSortBy);
  const sortDirection = useSelector(selectJobClosingSortDirection);

  const [selectedJobClosing, setSelectedJobClosing] = useState(null);

  const { data: jobClosingRules, isLoading, isError, error: jobClosingError } = useGetJobClosingRulesQuery();

  const jobClosingRulesColumns = [
    columnHelper.accessor('division', {
      header: 'Division',
    }),
    columnHelper.accessor('close_days', {
      header: 'Close After (Days)',
    }),
    columnHelper.accessor('', {
      cell: (info) => {
        const jobs = info.row.original;
        return (
          <HStack>
            <Tooltip label="Edit Job Closing" aria-label="Edit Job Closing">
              <Box p="0" as="span">
                <TableButton aria-label="edit-job-closing-rules" icon={<BsPencilSquare />} onClick={(e) => handleEditJobClosing(jobs)} />
              </Box>
            </Tooltip>
          </HStack>
        );
      },
      header: 'Action',
    }),
  ];

  const handleEditJobClosing = (job) => {
    setSelectedJobClosing(job);
    jobsModal.onOpen();
  };

  const handleSortBy = (column) => {
    dispatch(setSortBy(column));
  };

  const handleSortDirection = (direction) => {
    dispatch(setSortDirection(direction));
  };

  const handleSearchStatus = (status) => {
    dispatch(setSearchStatus(status));
  };

  const handleSaveNotification = () => {
    dispatch(setNotificationLogMessage({ msg: 'Job Closing Rule updated successfully', status: 'success' }));
  };

  return (
    <React.Fragment>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Job Closing Rules
      </Heading>
      <DataTableSearchAndFiltering
        title="Job Closing Rules"
        originalData={jobClosingRules}
        searchStatus={searchStatus}
        isSearchLoading={searchStatus === LOADING}
        isSearchError={searchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError || jobClosingError}
        dataErrorMsg={jobClosingError?.data?.detail}
        dataErrorTitle={'Error fetching job closing rules'}
        dataColumns={jobClosingRulesColumns}
        searchColumns={['division', 'close_days']}
        sortBy={sortBy}
        sortDirection={sortDirection}
        handleSortBy={handleSortBy}
        handleSortDirection={handleSortDirection}
        handleSearchStatus={handleSearchStatus}
      />
      <JobClosingModal
        onOpen={jobsModal.onOpen}
        onClose={jobsModal.onClose}
        isOpen={jobsModal.isOpen}
        mode={'edit'}
        onSave={handleSaveNotification}
        initialValue={selectedJobClosing}
      />
    </React.Fragment>
  );
};

export default JobClosingRules;
