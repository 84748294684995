import { useGetJobClosingRulesQuery } from '@/app/services/nucleus';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Layout from '../common/Layout';
import AlertComponent from '../common/AlertComponent';
import { Outlet } from 'react-router-dom';
import { useRole } from '@/hooks/useRole';

const JobClosing = () => {
  useRole('support');
  const [errorTitle, setErrorTitle] = useState('');
  const [errorDesc, setErrorDesc] = useState('');
  const { isError, error: jobClosingError, isLoading } = useGetJobClosingRulesQuery();

  useEffect(() => {
    if (isError) {
      setErrorTitle('Job Closing Error');
      setErrorDesc(jobClosingError?.data?.detail);
    }
  }, [isError]);

  return (
    <Box data-test-id="pact-users">
      <Layout pageTitle={'Pact'}>
        {isLoading ? (
          <Flex alignItems="center" justifyContent="center" py={200}>
            <Spinner color="brand.900" size="lg" />
          </Flex>
        ) : isError ? (
          <AlertComponent status="warning" title={errorTitle} description={errorDesc} />
        ) : (
          <Outlet />
        )}
      </Layout>
    </Box>
  );
};

export default JobClosing;
