import { useAddJobClosingRuleMutation } from '@/app/services/nucleus';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { BsListNested } from 'react-icons/bs';
import LoaderOverlay from '../common/LoaderOverlay';
import AlertComponent from '../common/AlertComponent';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import { selectDivisionsList } from '../enum/enumSlice';
import { useRole } from '@/hooks/useRole';

const JobClosingModal = ({ isOpen, onClose, onSave, initialValue, mode }) => {
  useRole('support');
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  const [division, setDivision] = useState(null);
  const [closeDays, setCloseDays] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const divisionList = useSelector(selectDivisionsList);

  const [
    addJobClosingRule,
    {
      isLoading: addJobClosingRuleLoading,
      isSuccess: addJobClosingRuleSuccess,
      isError: addJobClosingRuleError,
      error: addJobClosingRuleErrorMsg,
      reset: resetJobClosingRule,
    },
  ] = useAddJobClosingRuleMutation();

  useEffect(() => {
    if (!isOpen) {
      setDivision(null);
      setCloseDays('');
      setIsInvalid(false);
      resetJobClosingRule();
    }
  }, [isOpen]);

  useEffect(() => {
    if (initialValue && divisionList.length) {
      const { division: divisionValue, close_days: closeDaysValue } = initialValue;

      const divisionItem = divisionList.find((item) => item.value === divisionValue);

      setDivision(divisionItem || { label: divisionValue, value: divisionValue });
      setCloseDays(closeDaysValue || '');
    }
  }, [divisionList, initialValue]);

  const handleClose = () => {
    onClose();
  };

  const handleDivisionChange = (value) => {
    setDivision(value);
  };

  const handleCloseDaysChange = (e) => {
    const value = e.target.value;

    // Convert the value to an integer when necessary for validation
    const intValue = value ? parseInt(value, 10) : '';

    setCloseDays(value);

    if (value === '' || isNaN(intValue) || intValue <= 0) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetJobClosingRule();

    const jobsPayload = {
      division: division?.value,
      close_days: Number(closeDays),
    };

    addJobClosingRule(jobsPayload)
      .unwrap()
      .then((payload) => {
        console.log('Job Closing Rule Successfully Updated', payload);
        onSave(mode, payload);
        onClose();
      })
      .catch((error) => {
        console.log('Error Updating Job Closing Rule', error);
      });
  };

  return (
    <Modal
      title={'edit'}
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id={`${mode}-job-closing`} onSubmit={handleSubmit}>
          <ModalHeader>
            <HStack>
              <BsListNested />
              <Text>{'Edit Job Closing Rule'}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} position={'relative'}>
              <LoaderOverlay loading={addJobClosingRuleLoading} />

              {addJobClosingRuleError && (
                <AlertComponent status="error" title={'Error updating modality'} description={addJobClosingRuleErrorMsg?.data?.detail} />
              )}

              <FormControl aria-label={'division-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="division" mb={0} textAlign="end">
                      Division
                    </FormLabel>
                  </GridItem>
                  <Select
                    id="division"
                    aria-label="division"
                    placeholder="Select a Division"
                    focusBorderColor="brand.700"
                    options={divisionList}
                    value={{ label: initialValue?.division, value: initialValue?.division }}
                    onChange={handleDivisionChange}
                    isDisabled
                    isSearchable
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                  />
                </Grid>
              </FormControl>

              <FormControl isInvalid={isInvalid} aria-label={'close-days-control'}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="close_days" mb={0} textAlign="end">
                      Close Days
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="close_days"
                      focusBorderColor={'brand.700'}
                      type="number"
                      isRequired={false}
                      isDisabled={false}
                      value={closeDays}
                      onChange={handleCloseDaysChange}
                    />
                    {isInvalid && <FormErrorMessage>Must be greater than 0.</FormErrorMessage>}
                  </GridItem>
                </Grid>
              </FormControl>

              <HStack width="100%" py={3} justifyContent={'end'}>
                <Button variant="purple" type="submit">
                  {'Save'}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default JobClosingModal;
